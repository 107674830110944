@import './helpers';
@import 'react-slideshow-image/dist/styles.css';

a:-webkit-any-link {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

*:-webkit-any-link {
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: content-box !important;
}

.custom-date-picker,
.custom-input,
.text-input,
.time-slot,
.sc-dIfARi {
  *,
  *::before,
  *::after {
    box-sizing: border-box !important;
  }
}

.content-header {
  img {
    vertical-align: middle !important;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .main-container {
    @include displayFlex;
    @include fullWidth;
    flex-direction: column;
    align-items: center;
    height: 100vh;

    .header {
      width: 100%;
      @include displayFlex;
      justify-content: center;
      margin-bottom: 10px;
      padding-top: 40px;

      .okeep-logo {
        height: 54px;
        width: 131px;
      }
    }

    .user {
      position: absolute;
      right: 0;
      top: 0;

      .ellipse {
        align-items: flex-start;
        background-size: 100% 100%;
        @include displayFlex;
        height: 65px;
        justify-content: flex-end;
        margin-bottom: 1px;
        min-width: 65px;
        padding: 14px 11px;
        box-sizing: content-box;

        .avatar {
          height: 40px;
          object-fit: cover;
          width: 40px;
        }
      }
    }

    .content {
      @include displayFlex;
      align-items: center;
      justify-content: center;
      width: 70%;
      height: 410px;
      max-width: 400px;

      .content-container {
        @include displayFlex;
        flex-direction: column;
        align-items: center;
        border-radius: 22px;
        flex-wrap: nowrap;
        width: 100%;
        height: 100%;
        margin-top: 20px;
        box-shadow: 0px 4px 8px #00000040;
        box-sizing: content-box;

        .service-group {
          display: flex;
          width: 100%;

          .service {
            @include displayFlex;
            flex-direction: column;
            align-items: center;
            padding: 10px 0px;
            width: 50%;

            .service-img {
              background-color: white;
              border-radius: 50%;
              margin-bottom: 10px;
              width: 85px;
              height: 85px;
              box-shadow: 0px 4px 8px #00000040;
            }

            .service-text {
              font-family: $font-family-semi-bold;
              font-size: 12px;
              line-height: 14px;
              font-weight: 600;
              color: black;
              text-align: center;
            }
          }
        }

        .content-header {
          width: 100%;
          @include displayFlex;
          justify-content: space-between;
          align-items: center;
          color: white;
          height: 15%;
          background: rgba(0, 0, 0, 0.49);
          border-radius: 22px;
          margin-bottom: 10px;

          .back-button {
            width: 39px;
            height: 39px;
            margin: 10px;
            border-radius: 50%;
            box-shadow: 0px 4px 8px #00000040;
          }

          .content-title {
            font-family: $font-family-bold;
            font-size: 16px;
            line-height: 19px;
            font-weight: 700;
          }

          .content-title-airport {
            @include displayFlex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            height: 80%;

            .title {
              font-family: $font-family-bold;
              font-size: 16px;
              font-weight: 700;
              line-height: 19px;
              padding: 1px;
            }

            .description {
              font-family: $font-family-semi-bold;
              font-size: 12px;
              font-weight: 800;
              line-height: 15px;
              padding: 1px;

              &.clickable {
                font-weight: 500;
                text-decoration: underline;
              }
            }
          }

          .home-button {
            width: 39px;
            height: 39px;
            background-color: white;
            border-radius: 50%;
            box-shadow: 0px 4px 8px #00000040;
            margin: 10px;
          }

          .hidden {
            visibility: hidden;
            margin: 10px;
          }
        }

        .center {
          justify-content: center;
        }

        .content-body {
          color: white;
          padding: 0 10px;
          @include displayFlex;
          flex-direction: column;
          align-items: center;
          width: 90%;
          height: calc(85% - 10px);

          .cleaning-info-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 240px;
            margin-top: 20px;

            .cleaning-info-title {
              font-family: $font-family-bold;
              font-size: 12px;
              line-height: 14px;
              font-weight: 700;
              padding: 5px 0;
            }

            .cleaning-info-box {
              display: flex;
              background-color: rgba(0, 0, 0, 0.37);
              width: 100%;
              align-items: center;
              justify-content: space-between;
              border-radius: 10px;

              .title {
                font-family: $font-family-bold;
                font-size: 12px;
                line-height: 14px;
                font-weight: 700;
                padding: 10px 15px;
              }

              .price-container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 5px 15px;

                .price-usd {
                  font-family: $font-family-bold;
                  font-size: 15px;
                  line-height: 18px;
                  font-weight: 700;
                }

                .price-try {
                  font-family: $font-family-medium;
                  font-size: 10px;
                  line-height: 12px;
                  font-weight: 500;
                }
              }
            }
          }

          .cleaning-description {
            font-family: $font-family-semi-bold;
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;
            padding: 10px 0;
          }

          .nearby-services {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 10px 0;

            .nearby-service {
              display: flex;
              align-items: center;
              padding: 2px 20px;

              .nearby-service-icon {
                width: 20px;
                height: 20px;
                margin-right: 10px;
              }

              .nearby-service-title {
                font-family: $font-family-bold;
                font-size: 12px;
                line-height: 14px;
                font-weight: 700;
              }
            }
          }

          .more-information-container {
            display: flex;
            flex-direction: column;

            .info-icon {
              width: 15px;
              height: 15px;
              margin-right: 8px;
            }

            .more-information-text {
              font-family: $font-family-semi-bold;
              font-size: 10px;
              line-height: 12px;
              font-weight: 600;
              color: white;
              padding: 10px 0 0 0;
            }
          }

          .description {
            font-family: $font-family-bold;
            font-size: 12px;
            line-height: 15px;
            font-weight: 700;
            margin: 10px 0;
            width: 100%;
          }

          .title {
            font-family: $font-family-bold;
            font-size: 14px;
            line-height: 17px;
            font-weight: 700;
            padding: 10px;
          }

          .phone-container {
            width: 100%;
            @include displayFlex;
            align-items: center;
            justify-content: center;
          }

          .dental-button {
            @include displayFlex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: rgba(0, 0, 0, 0.3);
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 6px;
            width: calc(45% - 20px);
            height: calc(30% - 20px);
            margin: 5px 0;
            padding: 10px;

            .button-text {
              font-family: $font-family-bold;
              text-align: center;
              font-size: 12px;
              line-height: 15px;
              font-weight: 700;
              color: white;
              padding: 0 10px;
              width: 90px;
            }

            .button-icon {
              width: 60px;
              height: 60px;
            }
          }

          .telegram-button {
            display: none;
          }

          .info-container {
            @include displayFlex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 5px;
            background-color: #6741a7;
            height: 20px;
            border-radius: 6px;
            margin-bottom: 10px;

            .title {
              font-size: 12px;
              line-height: 14px;
              font-weight: 800;
            }

            .app {
              font-size: 12px;
              line-height: 14px;
              font-weight: 800;
              padding: 10px;
            }
          }

          .house {
            width: 35px;
            height: 35px;
          }

          .guest {
            width: 120px;
            height: 120px;
            background-color: white;
            border-radius: 50%;
            margin-top: 15px;
          }

          .content-text {
            @include displayFlex;
            padding: 20px;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            color: white;
            line-height: 19px;
            border-radius: 12px;
            margin-top: 20px;
            width: 80%;
            box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
          }

          .user-name {
            color: black;
            font-size: 15px;
            font-weight: 700;
            line-height: 17px;
            margin-top: 15px;
          }

          .transportation-services {
            @include displayFlex;
            justify-content: center;

            .service {
              text-align: center;

              .service-img {
                margin: 0 15px 10px 15px;
              }

              .service-text {
                color: white;
                font-size: 12px;
                font-weight: 900;
                line-height: 14px;
              }
            }
          }

          .question {
            font-size: 18px;
            font-weight: 900;
            text-align: center;
            width: 150px;
          }

          .transfer-description {
            font-size: 12px;
            line-height: 14px;
            font-weight: 800;
            height: 50px;
            width: 80%;
            text-align: center;
            margin-top: 10px;
            width: 150px;
          }

          .vip-taxi {
            height: 37px;
          }

          .question-airport {
            font-family: $font-family-bold;
            font-size: 16px;
            line-height: 19px;
            font-weight: 700;
            margin: 5px 0;
            margin-top: 20px;
          }

          .airport-container {
            width: 100%;
            @include displayFlex;
            flex-direction: column;
            align-items: center;

            .airport {
              @include displayFlex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background-color: rgba(0, 0, 0, 0.5);
              padding: 5px 0;
              margin-top: 10px;
              width: 90%;
              border-radius: 10px;
              box-shadow: 0px 4px 8px #00000040;
              color: white;

              .airport-title {
                font-family: $font-family-bold;
                font-size: 16px;
                line-height: 19px;
                font-weight: 700;
                padding: 2px;
              }

              .airport-text {
                font-family: $font-family-medium;
                font-size: 12px;
                font-weight: 500;
                padding-bottom: 2px;
              }
            }
          }

          .price-airport-main-container {
            @include displayFlex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            justify-content: flex-end;
            margin-top: 15px;

            .price-airport {
              font-size: 16px;
              font-weight: 700;
              line-height: 18px;
              padding: 5px;
            }

            .price-container {
              @include displayFlex;
              flex-direction: column;
              width: 90%;
              align-items: center;
              background: rgba(0, 0, 0, 0.37);
              margin-bottom: 10px;
              border-radius: 12px;

              .price-box {
                @include displayFlex;
                align-items: center;
                justify-content: space-between;
                width: 90%;

                .airport-title {
                  font-size: 12px;
                  line-height: 14px;
                  font-weight: 500;
                }

                .price {
                  padding: 5px;

                  .price-usd {
                    font-size: 17px;
                    font-weight: 700;
                    line-height: 19px;
                  }

                  .price-try {
                    font-size: 11px;
                    font-weight: 500;
                    line-height: 13px;
                  }
                }
              }
            }
          }

          .divider {
            height: 1px;
            background-color: white;
            width: 90%;
          }

          .scroll {
            overflow: scroll;
            height: calc(100% - 80px);
          }

          .date-picker-container {
            width: 100%;
            @include displayFlex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            .arrival-info {
              width: 90%;
              font-family: $font-family-medium;
              font-size: 12px;
              line-height: 14px;
              font-weight: 500;
              padding: 5px 0;
              text-align: left;

              b {
                font-family: $font-family-bold;
              }

              &:last-child {
                padding-bottom: 20px;
              }
            }

            .custom-date-picker {
              margin-top: 20px;
              border-radius: 20px;

              label {
                font-family: $font-family-bold;
                font-size: 16px;
                color: white;
                padding: 5px 0;
              }

              input {
                box-shadow: 0px 4px 8px #00000040;
              }

              .sc-gKPRtg {
                font-weight: 600;
              }
            }

            .service {
              width: 100%;

              .service-img {
                box-shadow: 0px 4px 8px #00000040;
                background-color: transparent;
                margin-bottom: 25px;
              }

              .service-text {
                font-size: 16px;
                line-height: 18px;
                text-align: center;
                font-weight: 800;
                color: white;
              }
            }

            .date-picker-button {
              height: auto;

              .service-img {
                width: 85px;
                height: 85px;
              }
            }
          }

          .request-container {
            background: rgba(0, 0, 0, 0.2);
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 12px;
            padding: 10px 15px;
            margin-bottom: 7px;

            .info {
              font-size: 14px;
              font-weight: 700;
              line-height: 16px;
            }

            .info-title {
              margin-bottom: 15px;
            }

            .info-date,
            .info-payment {
              span {
                color: #ffbb21;
              }
            }

            .info-payment {
              display: none;
              margin-top: 15px;
            }

            .info-house {
              margin-bottom: 15px;

              span {
                color: #ff4954;
              }
            }

            .info-request {
              margin-bottom: 15px;

              span {
                color: #ffbb21;
              }
            }
          }

          .send-buttons-container {
            display: flex;
            flex-direction: column;
            width: 100%;
          }

          .procedure-description {
            background: #d9d9d9;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 12px;
            margin-bottom: 5px;
            width: calc(100% - 20px);
            padding: 10px 10px;

            .text-container {
              .header,
              .body,
              .footer {
                font-family: $font-family-medium;
                padding-top: 0;
                color: black;
                font-size: 12px;
                font-weight: 500;
                line-height: 15px;
                margin-bottom: 10px;
                justify-content: flex-start;
              }

              .footer {
                margin-bottom: 0;
              }
            }

            .slide-container {
              width: 100%;
              margin: auto;
              margin-bottom: 10px;

              .each-slide {
                .lazy {
                  height: 20vh;
                  width: 100%;
                  object-fit: cover;
                  border-radius: 12px;
                }
              }
            }
          }

          .clinic-button-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .location-btn {
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              background: #a3a3a3;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 12px;
              padding: 0px 15px;
              width: calc(100% - 85px);
              min-width: 170px;
              height: 40px;
              margin-right: 10px;

              .title {
                font-size: 12px;
                font-weight: 700;
                line-height: 15px;
                padding: 0;
              }

              .address {
                font-family: $font-family-light;
                font-size: 10px;
                font-weight: 300;
                line-height: 12px;
              }
            }

            .instagram-btn {
              width: 45px;
              height: 45px;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 50%;
            }
          }

          .valuation-container {
            padding: 15px 20px;
            border-radius: 12px;
            @include displayFlex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 10px;

            .valuation-time {
              font-size: 12px;
              font-weight: 600;
              line-height: 14px;
              margin-bottom: 3px;
            }

            .valuation-dollar {
              font-size: 10px;
              font-weight: 600;
              line-height: 12px;
            }
          }

          .time-slot {
            margin-bottom: 5px;
            width: 100px;
            margin-top: 15px;

            label {
              color: white;
              font-size: 16px;
              font-weight: 700;
              line-height: 18px;
              margin-bottom: 10px;
              width: 150px;
              text-align: center;
            }

            .hCPuCI svg {
              width: 30px !important;
              height: 30px !important;
            }

            :nth-child(2) {
              :first-child {
                button {
                  color: white;
                  background: #d05963;
                  box-shadow: 0px 4px 8px #00000040;
                  left: -75px;
                  width: 50px;
                  height: 50px;
                }
              }

              :nth-child(2) {
                height: 80px;
                border-radius: 12px;
                background-color: #4a6aa2;
                color: white;
                font-size: 40px;
                font-weight: 700;
                padding: 20px;
              }

              :last-child {
                button {
                  color: white;
                  background: #4795ec;
                  box-shadow: 0px 4px 8px #00000040;
                  right: -75px;
                  width: 50px;
                  height: 50px;
                }
              }
            }
          }

          .cost-container {
            @include displayFlex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .cost-title {
              font-size: 12px;
              font-weight: 600;
              line-height: 18px;
            }

            .cost-value-usd {
              font-size: 14px;
              font-weight: 800;
              line-height: 16px;
            }

            .cost-value-try {
              font-size: 10px;
              font-weight: 600;
              line-height: 12px;
            }
          }

          .okey-button {
            width: 230px;
            height: 40px;
            margin-top: 10px;
            @include displayFlex;
            align-items: center;
            justify-content: center;
            background-color: rgb(71, 155, 65);
            box-shadow: 0px 4px 8px #00000040;
            border-radius: 12px;

            .okey-icon {
              width: 25px;
              height: 20px;
              border-radius: 10px;
            }
          }

          .arrival-information-container {
            @include displayFlex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            .info-title {
              font-family: $font-family-bold;
              font-size: 12px;
              font-weight: 700;
              line-height: 14px;
              color: white;
              margin-top: 10px;
              padding: 5px;
            }

            .arrival-information {
              @include displayFlex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;

              .info {
                @include displayFlex;
                justify-content: space-between;
                align-items: center;
                width: 90%;
                padding: 5px;

                .airport {
                  font-size: 11px;
                  font-weight: 700;
                  line-height: 13px;
                }

                .time {
                  font-size: 15px;
                  font-weight: 700;
                  line-height: 17px;
                }
              }
            }
          }

          .form-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;

            .form-body {
              font-family: $font-family-bold;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              height: 100%;
              justify-content: space-around;

              .form-title {
                font-size: 16px;
                font-weight: 700;
                line-height: 18px;
                color: black;
                margin-bottom: 5px;
              }

              .form-date-picker {
                width: 100%;
                display: flex;
                flex-direction: column;

                input {
                  padding-right: 1rem;
                }

                .custom-date-picker {
                  margin: 5px 0;

                  input {
                    background-color: rgba(0, 0, 0, 0.2);
                    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                    color: white;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 14px;

                    &::placeholder {
                      color: white;
                      font-size: 12px;
                      font-weight: 700;
                      line-height: 14px;
                    }
                  }
                }

                .custom-input {
                  margin: 5px 0;
                  border-radius: 20px;

                  input {
                    background-color: white;
                    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                    color: #000000b3;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 14px;

                    &::placeholder {
                      color: #0000004d;
                      font-size: 12px;
                      font-weight: 700;
                      line-height: 14px;
                    }
                  }
                }
              }

              .form-question-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .form-question {
                  font-size: 12px;
                  font-weight: 700;
                  line-height: 14px;
                  color: black;
                  padding-left: 5px;
                }

                .form-checkbox {
                  .rainbow-checkbox-toggle_faux {
                    height: 15px;
                    width: 35px;

                    &::after {
                      height: 12px;
                      width: 12px;
                      left: 1.5px;
                    }
                  }
                }
              }

              .form-information-box {
                font-family: $font-family-semi-bold;
                background: #e9e9e9;
                box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 12px;
                color: #000000;
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                display: flex;
                align-items: center;
                padding: 15px;
                margin-top: 10px;
              }
            }

            .clinident-logo {
              padding: 10px;
              width: 110px;
            }
          }

          .working-hours-container {
            font-family: $font-family-bold;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 95%;
            background: #d9d9d9;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 14px;
            color: #000000;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            justify-content: space-around;

            .working-hours {
              display: flex;
              justify-content: space-between;
              padding: 10px 20px;
              align-items: center;
            }

            .divider {
              width: 100%;
              height: 2px;
              background: #adadad;
            }
          }
        }

        .location-body {
          height: 100%;
          padding: 0;
        }

        .procedures-content-body {
          flex-wrap: wrap;
        }

        .warning {
          justify-content: center;
          height: calc(70% - 10px);

          .description {
            height: auto;
            width: auto;
            padding: 20px;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 18px;
          }
        }
      }

      .justify-content-center {
        justify-content: center;
      }

      .bg-yellow {
        background-color: #ffc42e;
      }

      .bg-green {
        background-color: #689f39;
      }

      .bg-turquoise {
        background-color: #39758f;
      }

      .bg-purple {
        background-color: #290366;
      }

      .bg-blue {
        background-color: #5f97fb;
      }

      .bg-gray {
        background-color: #575757;
      }

      .bg-dental {
        background-color: #e1e1e1;
      }
    }

    .title {
      @include displayFlex;
      align-items: center;
      justify-content: center;
      line-height: normal;

      .txt {
        font-family: $font-family-bold;
        font-weight: 700;
        font-size: 18px;
        margin-right: 10px;
        color: black;
      }

      .icon {
        width: 25px;
        height: 25px;
      }
    }

    .social-media-container {
      .social-media {
        padding: 20px;

        .text {
          font-size: 12px;
          font-weight: 600;
          line-height: 14px;
          margin-bottom: 10px;
        }
      }
    }

    .action-button {
      display: flex;
      margin: 30px 0;
      padding: 15px;
      border-radius: 12px;
      width: calc(70% - 30px);
      max-width: 370px;
      justify-content: space-between;
      align-items: center;
      color: white;

      .button-text {
        font-family: $font-family-bold;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
      }
    }

    .navigator-button {
      @include displayFlex;
      background-color: #304d80;
      border-radius: 12px;
      width: 70%;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 4px 8px #00000040;
      margin: 30px 0;
      padding: 5px 0;

      .button-text-container {
        @include displayFlex;
        font-family: $font-family-bold;
        flex-direction: column;
        justify-content: center;
        padding: 10px 15px;

        .button-text {
          font-size: 16px;
          font-weight: 700;
          line-height: 18px;
          color: white;
        }
      }

      .button-icon {
        padding-right: 10px;
        width: 30px;
        height: 30px;
      }
    }
  }

  .modal-container {
    @include displayFlex;
    position: absolute;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    width: 80%;
    max-width: 765px;
    max-height: 80%;
    border-radius: 10px;
    padding: 20px;
    margin: auto;
    background-color: #e9e9e9;
    padding-top: 0;
    padding-bottom: 10px;

    .modal-header {
      font-family: $font-family-bold;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      width: 100%;
      background-color: #f6b545;
      padding: 16px 20px;
      border-radius: 10px;
      color: white;
    }

    .header-image {
      width: 100%;
      height: 150px;
      margin: 10px 0 0 0;
      border-radius: 14px;
      object-fit: cover;
    }

    .modal-body {
      overflow: scroll;
      margin: 10px 0;
      width: calc(100% - 20px);
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 14px;
      padding: 10px;
      background: #d9d9d9;

      .header-text {
        font-family: $font-family-medium;
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        margin-bottom: 10px;

        b {
          font-family: $font-family-bold;
        }
      }
    }

    .button {
      width: calc(100% - 20px);

      .button-icon {
        width: 30px;
        height: 30px;
        padding: 5px;
      }
    }

    .close-btn {
      position: absolute;
      top: 7px;
      right: 8px;
      color: white;
      background-color: #ff4954;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .opportunity-modal {
    width: 100%;
    height: 100%;
    max-height: 100%;
    background-color: white;

    .modal-logo {
      width: 131px;
      margin: 45px 0;
    }

    .opportunity-modal-body {
      width: 300px;
      height: 450px;
      background-color: #e9e9e9;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 22px;
      position: relative;

      .header-opportunity-image {
        position: absolute;
        width: 80px;
        height: 80px;
        left: calc(50% - 40px);
        top: -40px;
      }

      .opportunity-modal-container {
        font-family: $font-family-bold;
        display: flex;
        padding: 45px 30px 0 30px;
        flex-direction: column;
        height: 85%;
        align-items: center;

        .header-video {
          width: 100%;
          height: 35%;
          margin-bottom: 15px;
          border-radius: 10px;
          object-fit: cover;
        }

        .opportunity-modal-text {
          text-align: center;
          font-size: 12px;
          line-height: 14px;
          font-weight: 700;
          margin-bottom: 15px;
          width: 90%;
        }

        .discount-message {
          text-align: center;
          font-size: 18px;
          line-height: 21px;
          font-weight: 700;
          margin-bottom: 15px;
        }

        .clinic-button-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          margin-bottom: 15px;

          .confirm-btn {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background: #ff4954;
            color: white;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 12px;
            width: calc(100% - 50px);
            min-width: 100px;
            height: 35px;
            margin-right: 10px;

            .title {
              font-size: 12px;
              font-weight: 700;
              line-height: 14px;
              padding: 0;
            }
          }

          .instagram-btn {
            width: 40px;
            height: 40px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            border-radius: 50%;
            stroke: #290366;
            fill: #290366;
          }
        }

        .clinident-logo {
          width: 100px;
          margin: 10px auto;
          position: absolute;
          bottom: 0;
        }
      }

      .close-btn {
        width: 14px;
        height: 14px;
      }
    }
  }

  .button {
    @include displayFlex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    color: black;
    padding: 5px 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px #00000040;
    width: calc(100% - 20px);
    margin-bottom: 10px;

    .button-icon {
      width: 35px;
      height: 35px;
      margin-right: 10px;
    }

    .button-text {
      font-family: $font-family-bold;
      font-size: 12px;
      line-height: 14px;
      font-weight: 800;
      width: 100%;
      position: absolute;
      left: 0;
      text-align: center;
    }
  }

  .sc-dIfARi {
    .sc-hHTYSt {
      width: 90% !important;
      margin: auto;
      border-radius: 10px !important;
    }
  }

  .fxOzKw {
    font-weight: 700;
  }

  .hLaXSa,
  .eaCDAk {
    color: black;
  }
  .hWcvJH,
  .scAwt {
    width: 30%;
    font-weight: 300 !important;
    color: black !important;
  }

  .sc-cZFQFd,
  .bflkTn,
  .gmnoprint {
    display: none;
  }

  .gm-control-active,
  .gm-fullscreen-control {
    display: block !important;
  }
}
