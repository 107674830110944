@import './helpers';
@import 'react-slideshow-image/dist/styles.css';

.info-bottom-container {
  width: 100%;

  .info-bottom-container-title {
    font-family: $font-family-bold;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: white;
    padding: 10px;
    text-align: center;
  }
  .info-bottom-container-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    height: 80px;
    justify-content: center;

    .info-container {
      display: flex;
      padding: 5px 20px;
      align-items: center;
      justify-content: space-between;
      color: white;

      .info-title {
        font-family: $font-family-bold;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
      }

      .info-desc {
        .first-desc {
          font-family: $font-family-bold;
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
        }

        .second-desc {
          font-family: $font-family-medium;
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
}

.vehicle-rules-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .vehicle-rules {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .rule {
      display: flex;
      align-items: center;

      .rule-img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }

      .rule-text {
        font-family: $font-family-semi-bold;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
      }

      .rule-text-bold {
        font-family: $font-family-bold;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
}

.text-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .text-input {
    width: 90%;

    label {
      font-family: $font-family-bold;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: white;
      margin-bottom: 5px;
    }

    input {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      &::placeholder {
        font-family: $font-family-semi-bold;
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
      }
    }
  }
}
