$page-bg-color: #ffffff;
$gray-background: #cccbc482;
$gray300: #b9bbbc;
$vivid-red: #e12a2a;
$breakpoint-tablet: 768px;

@mixin hide {
  display: none;
}

@mixin fullWidth {
  width: 100%;
}

@mixin positionAbsolute {
  position: absolute;
}

@mixin displayFlex {
  display: flex;
}

@font-face {
  font-family: 'Monsterrat Medium';
  src: url('../fonts/Montserrat-Medium.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monsterrat Bold';
  src: url('../fonts/Montserrat-Bold.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monsterrat SemiBold';
  src: url('../fonts/Montserrat-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monsterrat ExtraBold';
  src: url('../fonts/Montserrat-ExtraBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monsterrat Light';
  src: url('../fonts/Montserrat-Light.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

$font-family-medium: 'Monsterrat Medium', sans-serif;
$font-family-bold: 'Monsterrat Bold', sans-serif;
$font-family-semi-bold: 'Monsterrat SemiBold', sans-serif;
$font-family-extra-bold: 'Monsterrat ExtraBold', sans-serif;
$font-family-light: 'Monsterrat Light', sans-serif;
